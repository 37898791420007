@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/ocy5iub.css");

.map_svg rect:not(.skip):hover,
.map_svg path:not(.skip):hover {
    fill: orange !important;
}

.map_svg .room:not(.skip):hover,
.map_svg .room_inner:not(.skip):hover {
    fill: green !important;
}

.frontend_svg .mapped:hover {
    fill: orange !important;
}

.uc {
    font-family: acumin-pro, sans-serif;
}

.uc h1,
.uc h2,
.uc h3,
.uc h4,
.uc h5,
.uc h6 {
    font-family: all-round-gothic, sans-serif;
}

pre {
    font-family: arial;
}

@media only screen and (max-width: 767px) {
    .uc_short_bg {
        background: url("https://apply.urbancircle.co.za/images/uc_step1_short_bg.jpg") no-repeat center center;
        background-size: cover;
        min-height: 100vh;
    }
}

@media only screen and (min-width: 768px) {
    .uc_short_bg {
        background: url("https://apply.urbancircle.co.za/images/uc_short_people.png") no-repeat 95% 20px, url("https://apply.urbancircle.co.za/images/uc_step1_short_bg.jpg") no-repeat center center;
        background-size: auto 350px, cover;
        min-height: 100vh;
    }
}

@media only screen and (min-width: 768px) {
    .white_block_left {
        margin-left: -200px;
        margin-right: 200px;
        border-top-right-radius: 50px;
        padding-left: 200px;
    }
}

.PhoneInput > input {
    width: 90%;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.ucnew > .PhoneInput > input {
    width: 90%;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: rgb(23 39 59 / 0.1);
}

.application_form {
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(/public/images/qliving_forest_views.jpg);
}

.colour-uc2 {
    background: #ff7c98;
}
.colour-uc3 {
    background: #ffa7b9;
}
.colour-uc4 {
    background: #ffc4d1;
}
.colour-uc5 {
    background: #fff2f5;
}

.colour-uc6 {
    background: #00afb1;
}

.uc_prop_border1 {
    border: 4px solid #00afb1;
    border-top-right-radius: 27px;
    border-bottom-right-radius: 27px;
}

.ql_prop_border1 {
    border: 4px solid #9f7d38;
    border-top-right-radius: 27px;
    border-bottom-right-radius: 27px;
}

.rdw-suggestion-dropdown {
    position: relative !important;
    max-width: 200px;
    border-radius: 20px;
    padding: 1px 5px;
}

.rdw-mention-link {
    background: #dbdbdb !important;
}

.rdw-suggestion-option:hover {
    cursor: pointer;
}

.dangerousPFormat p:not(:last-child) {
    margin-bottom: 1.2em;
}

.rdw-editor-main {
    max-height: 90px;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
    z-index: 5 !important;
}
